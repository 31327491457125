import { useContext } from 'react';
import styled, { css } from 'styled-components';

import { AppContext } from 'App/AppContext';
import { Theme } from 'types';

const sharedStyles = css`
  transition: color 0.5s linear;
  font-weight: normal;
  position: relative;
  z-index: 1;
`;

const C = {
  Address: styled.h1<{ $theme: Theme }>`
    ${sharedStyles};
    font-size: 2rem;
    margin:
      20px,
      0 auto;
    color: ${({ $theme }) => $theme.primaryTextColor};
    @media only screen and (max-device-width: 820px) and (-webkit-min-device-pixel-ratio: 2) {
      font-size: 1.5rem;
    }
  `,
};

export const Address = () => {
  const { config, theme } = useContext(AppContext);

  return (
    <>
      <C.Address data-v2="address" $theme={theme}>
        {config.address.display}
      </C.Address>
    </>
  );
};
