import { Config } from 'types';
import { Email, GitHub, Twitter, Resume, Phone } from 'icons';

export const config: Config = {
  name: {
    display: 'Aoi Kurokawa',
  },
  title: {
    display: 'Software Engineer',
  },
  address: {
    display: '64 Ihumata Road Auckland New Zealand',
  },
  buttons: [
    {
      name: 'github',
      display: 'GitHub',
      ariaLabel: 'GitHub profile (opens in new window)',
      icon: <GitHub />,
      href: 'https://github.com/aoikurokawa1030/',
    },
    {
      name: 'twitter',
      display: 'Twitter',
      ariaLabel: 'Twitter profile (opens in new window)',
      icon: <Twitter />,
      href: 'https://x.com/AoiKurokawa118',
    },
    {
      name: 'resume',
      display: 'Resume',
      ariaLabel: 'Resume in Google Drive (opens in new window)',
      icon: <Resume />,
      href: 'https://drive.google.com/file/d/1sh6dp9h3Xm5YHW7KazQXgIkeCDR2YCSZ/view',
    },
    {
      name: 'email',
      display: 'Email',
      ariaLabel: 'Email contact (opens in new window)',
      icon: <Email />,
      href: 'mailto:aoi.kurokawa.118@gmail.com',
    },
    {
      name: 'phone',
      display: 'Phone Number',
      ariaLabel: 'Phone Number (opens in new window)',
      icon: <Phone />,
      href: 'tel:+1 3236173788',
    },
  ],
};
